const wdays = ['日', '月', '火', '水', '木', '金', '土']

export default class DateFormatter {
	constructor (date) {
		this.date = date
	}

	month () {
		const { date } = this
		return [date.getFullYear(), '年', date.getMonth() + 1, '月'].join('')
	}

	weekday () {
		return wdays[this.date.getDay()]
	}
}
